import React from 'react';
import { motion } from 'framer-motion';

function TestimonialCard({ name, role, content, image }) {
    return (
        <motion.div
            className="bg-white p-8 rounded-lg border border-gray-200 hover:border-black transition-all duration-300"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <p className="text-gray-700 text-lg mb-6 italic">"{content}"</p>
            <div className="flex items-center">
                <img src={image} alt={name} className="w-16 h-16 rounded-full mr-4 object-cover" />
                <div>
                    <h4 className="font-bold text-black text-xl">{name}</h4>
                    <p className="text-gray-600">{role}</p>
                </div>
            </div>
        </motion.div>
    );
}

function Testimonials() {
    const testimonials = [
        {
            name: "Marco Rossi",
            role: "Atleta dilettante",
            content: "AI Trainer ha trasformato completamente il mio approccio all'allenamento. I piani personalizzati e l'adattabilità quotidiana sono fantastici!",
            image: "/placeholder-image-1.jpg"
        },
        {
            name: "Laura Bianchi",
            role: "Maratoneta",
            content: "Grazie ad AI Trainer ho migliorato notevolmente le mie prestazioni. L'intelligenza artificiale capisce davvero le mie esigenze e si adatta ai miei progressi",
            image: "/placeholder-image-2.jpg"
        },
        {
            name: "Giovanni Verdi",
            role: "Appassionato di fitness",
            content: "Non avrei mai pensato che un'app potesse sostituire un personal trainer, ma AI Trainer ci è riuscito. È come avere un allenatore esperto sempre con me",
            image: "/placeholder-image-3.jpg"
        }
    ];

    return (
        <section className="py-24 bg-gray-100">
            <div className="container mx-auto px-4">
                <motion.div
                    className="text-center mb-16"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-5xl font-bold text-black mb-6">TESTIMONIANZE UTENTI</h2>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                        Ascoltate i nostri utenti soddisfatti della loro esperienza con AI Trainer
                    </p>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <TestimonialCard key={index} {...testimonial} />
                    ))}
                </div>
                <motion.div
                    className="text-center mt-16"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <button className="bg-black text-white px-8 py-3 text-lg font-semibold rounded-lg hover:bg-gray-800 transition-colors duration-300">
                        Leggi altre storie
                    </button>
                </motion.div>
            </div>
        </section>
    );
}

export default Testimonials;