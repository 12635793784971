import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const menuItems = [
        { name: 'HOME', path: '/' },
        { name: 'CONTATTI', path: '/contact' },
        { name: 'LOGIN', path: '/comingSoon' },
    ];

    return (
        <header className="bg-black text-white">
            <nav className="container mx-auto px-4 py-6 flex justify-between items-center">
                <div className="flex items-center">
                    <motion.img
                        src={process.env.PUBLIC_URL + '/images/logo_bianco.png'}
                        alt="AI Trainer Studio Logo"
                        className="h-10 w-auto mr-4" // Adatta le dimensioni come necessario
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    />
                    <motion.h1
                        className="text-white text-3xl font-bold hidden md:flex"
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                    >
                        AI TRAINER STUDIO
                    </motion.h1>
                </div>
                {/* Desktop Menu */}
                <motion.ul
                    className="hidden md:flex space-x-20 text-white"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.2}}
                >
                    {menuItems.map((item) => (
                        <li key={item.name}>
                            <Link to={item.path} className="hover:text-gray-300 transition-colors">
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </motion.ul>

                {/* Hamburger Menu Icon */}
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </nav>

            {/* Mobile Menu */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        className="md:hidden"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ul className="flex flex-col items-center py-4">
                            {menuItems.map((item) => (
                                <motion.li
                                    key={item.name}
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.2 }}
                                    className="py-2"
                                >
                                    <Link
                                        to={item.path}
                                        className="text-white hover:text-gray-300 transition-colors"
                                        onClick={toggleMenu}
                                    >
                                        {item.name}
                                    </Link>
                                </motion.li>
                            ))}
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </header>
    );
}

export default Header;