import React from 'react';
import { motion } from 'framer-motion';

function StepCard({ title, description, image, index }) {
    return (
        <motion.div
            className="flex flex-col lg:flex-row items-center mb-24 bg-gray-100 rounded-lg overflow-hidden"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
        >
            <div className="lg:w-1/2 p-12">
                <h3 className="text-4xl font-bold mb-6 text-black">{title}</h3>
                <p className="text-xl text-gray-600 leading-relaxed mb-8">{description}</p>

            </div>
            <div className="lg:w-1/2">
                <img src={image} alt={title} className="w-full h-full object-cover" />
            </div>
        </motion.div>
    );
}

function HowItWorks() {
    const steps = [
        {
            title: "01 - CREA IL TUO PROFILO",
            description: "Inserisci i tuoi dati, i tuoi obiettivi e le tue preferenze, in base alla creazione del nostro ecosistema dell’AI-POWERED personalizzeremo la tua esperienza in modo unico!",
            image: `${process.env.PUBLIC_URL}/images/bg1.jpg`
        },
        {
            title: "02 - AI GENERA IL TUO PIANO",
            description: " Sulla base del vostro profilo la nostra AI svilupperà un programma d’allenamento tenendo conto della vostra routine quotidiana e dei vostri obiettivi specifici",
            image: `${process.env.PUBLIC_URL}/images/bg3.jpg`
        },
        {
            title: "03 - ALLENARSI E MIGLIORARE",
            description: "Segui il tuo piano personalizzato, traccia i tuoi progressi in tempo reale e testimonia i risultati. \n" +
                "L’AI-POWERED si adatta continuamente per ottimizzare il vostro percorso fitness.",
            image: `${process.env.PUBLIC_URL}/images/bg4.jpg`
        }
    ];

    return (
        <section className="py-24 bg-white">
            <div className="container mx-auto px-4">
                <motion.div
                    className="text-center mb-20"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-5xl font-bold text-black mb-6">COME FUNZIONA</h2>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">Sperimentate il futuro del fitness con il nostro sistema di allenamento basato sull'IA</p>
                </motion.div>
                {steps.map((step, index) => (
                    <StepCard key={index} {...step} index={index} />
                ))}
            </div>
        </section>
    );
}

export default HowItWorks;