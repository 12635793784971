import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';


function Hero() {

    const navigate = useNavigate();
    const handleCtaClick = () => {
        console.log('CTA button clicked');
        navigate('/comingSoon');
    };


    return (
        <section className="relative h-screen bg-gray-100 overflow-hidden">
            {/* Background Image */}
            <div className="absolute inset-0">
                <img
                    src={process.env.PUBLIC_URL + '/images/bg2.jpg'}
                    alt="Athletes"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-60"></div>
            </div>

            {/* Content */}
            <div className="relative z-10 h-full flex flex-col">
                {/* Main Content */}
                <div className="container mx-auto px-4 flex-grow flex items-center">
                    <motion.div
                        className="max-w-2xl"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.4 }}
                    >
                        <h2 className="text-white text-5xl md:text-6xl font-bold mb-6">
                            LIBERATE IL VOSTRO <br/> POTENZIALE CON L'AI
                        </h2>
                        <p className="text-gray-300 text-lg mb-8">
                            Entrate in un mondo in cui le prestazioni, il lavoro UMANO incontrano l'innovazione, in cui
                            ogni allenamento, ogni tipo di obiettivo e ogni risultato è alimentato dal potenziale di
                            AI-POWERED. Noi del Team di AI Trainer studio non ci limitiamo a creare piani di fitness, ma
                            creiamo esperienze in cui puoi ambire alla grandezza, sotto ogni punto di vista!

                        </p>
                        <motion.button
                            className="bg-white text-black px-8 py-3 rounded-full font-semibold"
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                            onClick={handleCtaClick}
                        >
                            Inizia Il Tuo Viaggio
                        </motion.button>
                    </motion.div>
                </div>

                {/* Bottom Bar */}
                <motion.div
                    className="bg-black bg-opacity-80 py-2 "
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <div className="container mx-auto px-2 flex flex-col justify-between items-center">
                        <div className="text-white font-bold mb-2">
                            AI TRAINER® BY NICOLÒ POLIDORI
                        </div>
                        <div className="flex text-center mb-2">
                            <span className="text-gray-300 text-[10px] ">*L’AI POWERED NON GENERA PROGRAMMI SENZA PRIMA AVER AVUTO UNO STUDIO DAL NOSTRO TEAM DI TRAINER E NUTRIZIONISTI CREANDO COSÌ UN ECOSISTEMA IN CUI L’AI-POWERED FARÀ DA TRAMITE PER UN ESPERIENZA SICURA E CONTROLLATA</span>
                        </div>
                    </div>
                </motion.div>
            </div>

            {/* Decorative Elements */}
            <svg className="hidden absolute top-0 left-0 w-full h-full md:flex" xmlns="http://www.w3.org/2000/svg">
                <rect width="100" height="100" fill="none" stroke="white" strokeWidth="0.5" x="20" y="20" />
                <rect width="100" height="100" fill="none" stroke="white" strokeWidth="0.5" x="80%" y="70%" />
            </svg>
        </section>
    );
}

export default Hero;