import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function CTASection() {
    return (
        <section className="bg-black py-24 relative overflow-hidden">
            <div className="container mx-auto px-4 relative z-10">
                <motion.div
                    className="max-w-3xl mx-auto text-center"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <h2 className="text-5xl font-bold text-white mb-6">
                        ELEVA IL TUO PERCORSO DI FITNESS
                    </h2>
                    <p className="text-xl text-gray-300 mb-10">
                        Unisciti a migliaia di utenti che stanno già sperimentando la potenza degli allenamenti guidati dall'intelligenza artificiale. Trasformate il vostro allenamento, raggiungete i vostri obiettivi e liberate tutto il vostro potenziale.
                    </p>
                    <Link
                        to="/comingSoon"
                        className="bg-white text-black font-bold py-4 px-10 text-lg inline-block hover:bg-gray-200 transition-colors duration-300"
                    >
                        INIZIA LA PROVA GRATUITA
                    </Link>
                    <p className="text-sm text-gray-400 mt-6">
                        14 giorni di prova gratuita. Non è richiesta la carta di credito.
                    </p>
                </motion.div>
            </div>

            {/* Decorative elements */}
            <div className="absolute top-0 left-0 w-full h-full">
                <svg className="absolute top-0 left-0 h-full" width="400" height="100%" viewBox="0 0 400 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-200" y="200" width="800" height="5" transform="rotate(-45 -200 200)" fill="white" fillOpacity="0.1"/>
                    <rect x="-150" y="250" width="800" height="5" transform="rotate(-45 -150 250)" fill="white" fillOpacity="0.1"/>
                    <rect x="-100" y="300" width="800" height="5" transform="rotate(-45 -100 300)" fill="white" fillOpacity="0.1"/>
                </svg>
                <svg className="absolute top-0 right-0 h-full" width="400" height="100%" viewBox="0 0 400 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="600" y="200" width="800" height="5" transform="rotate(45 600 200)" fill="white" fillOpacity="0.1"/>
                    <rect x="550" y="250" width="800" height="5" transform="rotate(45 550 250)" fill="white" fillOpacity="0.1"/>
                    <rect x="500" y="300" width="800" height="5" transform="rotate(45 500 300)" fill="white" fillOpacity="0.1"/>
                </svg>
            </div>
        </section>
    );
}

export default CTASection;