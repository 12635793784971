import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import ContactPage from './pages/ContactPage';
import LoginSignupPage from "./pages/LoginSignupPage";
import ComingSoonPage from "./pages/ComingSoonPage";

function App() {
  return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/login" element={<LoginSignupPage />} />
                <Route path="/comingSoon" element={<ComingSoonPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
  );
}

export default App;