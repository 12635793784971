import React from 'react';
import Hero from '../components/landingpage/Hero';
import Features from '../components/landingpage/Features';
import HowItWorks from '../components/landingpage/HowItWorks';
import Testimonials from '../components/landingpage/Testimonials';
import CTASection from '../components/landingpage/CTASection';

function LandingPage() {
    return (
        <div>
            <Hero />
            <Features />
            <HowItWorks />
            <Testimonials />
            <CTASection />
        </div>
    );
}

export default LandingPage;