import React from 'react';
import { motion } from 'framer-motion';

function FeatureCard({ title, description, index }) {
    return (
        <motion.div
            className="bg-white p-8 border-2 border-gray-200 hover:border-black transition-all duration-300 rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
        >
            <h3 className="text-2xl font-bold text-black mb-4">{title}</h3>
            <p className="text-gray-600 leading-relaxed">{description}</p>
        </motion.div>
    );
}

function Features() {
    const features = [
        {
            title: "ALLENAMENTI AI-POWERED",
            description: "La nostra intelligenza artificiale avanzata adatta ogni tipo di allenamento agli obiettivi da raggiungere, dalla forma fisica mentale fino all’energia dell’utente, assicurando progressione e prevenendo infortuni!",
        },
        {
            title: "ADATTAMENTO IN TEMPO REALE",
            description: "L’intelligenza artificiale adatta continuamente il piano d’allenamento in base alle prestazioni, al recupero e al feedback, massimizzando i risultati  e riducendo al minimo il rischio di eventuali infortuni.",
        },
        {
            title: "MONITORAGGIO COMPLETO",
            description: "Monitorate i vostri progressi con analisi dettagliate, visualizzando i vostri miglioramenti nel tempo e mantenendovi motivati per raggiungere nuovi traguardi!",
        },
        {
            title: "ALIMENTAZIONE PERSONALIZZATA",
            description: "Ricevi programmi nutrizionali scientificamente studiati dai nostri esperti nutrizionisti e poi personalizzati per te attraverso l'intelligenza artificiale. L'AI-POWERED adatta le linee guida professionali alle tue specifiche esigenze, creando un piano alimentare su misura per i tuoi obiettivi.",
        }
    ];

    return (
        <section className="py-24 bg-gray-100">
            <div className="container mx-auto px-4">
                <motion.div
                    className="text-center mb-16"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                >
                    <h2 className="text-5xl font-bold text-black mb-4">Sfrutta il tuo potenziale</h2>.
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">Scopri come AI TRAINER rivoluziona il tuo
                        percorso di fitness</p>
                </motion.div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} index={index}/>
                    ))}
                </div>
                <motion.div
                    className="text-center mt-16"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <button className="bg-black text-white px-10 py-4 text-lg font-semibold hover:bg-gray-800 transition-colors duration-300 rounded-lg">
                        ESPLORA TUTTE LE FUNZIONI
                    </button>
                </motion.div>
            </div>
        </section>
    );
}

export default Features;