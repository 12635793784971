import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="mb-16">
            {/* Decorative element */}
            <div className="border-t border-gray-200 pt-16">
                <div className="container mx-auto px-4">
                    <p className="text-center text-gray-500 text-sm">
                        AI TRAINER © 2024 | Revolutionizing Fitness Through Technology
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;